import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { FormControl } from "@angular/forms";
import * as moment from "moment";
import { SupplierService } from "src/app/services/supplier.service";
import { AlertInfo } from "src/app/interfaces/alertInfo";
import { from } from "rxjs";
import {
  ButtonCommands,
  DialogType,
  DialogService,
} from "src/app/services/dialog.service";
import { Router } from "@angular/router";
import { LanguageService } from "../../../../dynamic-components/utils/language.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.sass"],
})
export class AlertComponent implements OnInit {
  // itemsPerPage = 6
  // currentPage = 1
  private alertBar: any;

  isValidated = false;

  alertInfo: AlertInfo = {};

  constructor(
    private supplierService: SupplierService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.searchAlertBar();
    this.alertInfo.CreateTime = new Date();
  }

  // pageChange(event) {
  //   this.currentPage = event
  // }

  async searchAlertBar() {
    const alertBar: any = await this.supplierService.queryLastAlertBar();
    if (alertBar && alertBar.data) {
      alertBar.data.StartTime = moment(alertBar.data.StartTime).utc().toDate();
      alertBar.data.EndTime = moment(alertBar.data.EndTime).utc().toDate();
      this.alertInfo = alertBar.data;
    }
  }

  async delete(alertInfo: AlertInfo) {
    const result: any = await this.supplierService.deleteAlertBar(alertInfo);
    if (result.isSuccess) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.alert.Delete"
          ),
          ButtonCommands.Close,
          DialogType.success,
          this.languageService.getValue(
            "static.system.components.alert.Success"
          )
        );
      } else {
        await this.dialogService.dialog(
          "You have delete successfully.",
          ButtonCommands.Close,
          DialogType.success,
          "Success"
        );
      }
      this.router.navigate(["buyer"]);
    }
  }

  validate() {
    this.isValidated = true;
    if (
      !this.alertInfo.AlertMessage ||
      !this.alertInfo.StartTime ||
      !this.alertInfo.EndTime
    ) {
      return false;
    }
    return true;
  }

  async save() {
    if (!this.validate()) {
      return;
    }
    this.alertInfo.Enabled = true;
    // console.log("this.alertInfo.start", this.alertInfo.StartTime);
    // console.log("this.alertInfo.end", this.alertInfo.EndTime);
    const result: any = await this.supplierService.saveAlertBar(this.alertInfo);
    // console.log(result);
    if (result.isSuccess) {
      if (environment.languageSwitch && environment.role == "supplier") {
        await this.dialogService.dialog(
          this.languageService.getValue(
            "static.system.components.alert.Submitted"
          ),
          ButtonCommands.Close,
          DialogType.success,
          this.languageService.getValue(
            "static.system.components.alert.Success"
          )
        );
      } else {
        await this.dialogService.dialog(
          "You have submitted successfully.",
          ButtonCommands.Close,
          DialogType.success,
          "Success"
        );
      }
      this.router.navigate(["buyer"]);
    }
  }
}
