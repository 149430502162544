import { Component, OnInit, DoCheck } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth/auth.service";
import { ProfileService } from "../../services/profile.service";
import { CommonService } from "src/app/services/common.service";
import { OnBoardingStatus } from "src/app/interfaces/supplier";
// import { NotificationInfo, NotificationType, NotificationMessage } from 'src/app/interfaces/notification'
import { SupplierService } from "src/app/services/supplier.service";
import { SupplierStatus } from "src/app/interfaces/mapping";
// import { checkDetailStatus } from 'src/app/interfaces/supplierModel'
import { NotificationService } from "src/app/services/notification.service";
import { SupplierHeaderService } from "src/app/modules/supplier/services/header.service";
import { from } from "rxjs";
import {
  AllTaskKey,
  ProcessNames,
  SetupProcess,
  ProcessStatus,
  MigrateProcess,
} from "src/app/interfaces/workflow";
import { environment } from "../../../../../environments/environment";
import { getTSMCountryServedQuery } from "../../../../shared/shared";

@Component({
  selector: "app-landing-header",
  templateUrl: "./landing-header.component.html",
  styleUrls: ["./landing-header.component.sass"],
})
export class LandingHeaderComponent implements OnInit, DoCheck {
  get isCompleted(): boolean {
    return this.getIsCompleted();
  }

  get isTsmId(): boolean {
    return !!this.authService.passport.supplier.Mapping.TsmId;
  }

  get isMigrated(): boolean {
    return this.authService.passport.supplier.Mapping.IsMigrated;
  }
  message: string;
  authService: AuthService;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
    private commonService: CommonService,
    private supplierService: SupplierService,
    private supplierHeaderService: SupplierHeaderService,
    private notificationService: NotificationService
  ) {
    this.authService = _authService;
  }

  async ngOnInit() {
    if (this.authService.passport.supplier) {
      this.notificationService.loadData();
      this.supplierHeaderService.getIsCompletedAndIsSSTRejectOrQCApprove();
      await this.profileService.loadUserTasks();
    }
  }

  async ngDoCheck() {
    if (this.authService.passport.supplier) {
      this.supplierHeaderService.getIsCompletedAndIsSSTRejectOrQCApprove();
    }
  }
  // async formatMessage() {
  //   if (this.notification && this.notification.To === this.authService.passport.supplier.Mapping.supplierCode) {
  //     this.message = NotificationMessage[this.notification.NotificationType]
  //     return
  //   }
  //   this.message = null
  // }

  navigateTo(type) {
    if (type === "process") {
      this.router.navigate([
        `supplier/onboarding/process/${this.authService.passport.supplier.Mapping.supplierCode}`,
      ]);
    } else if (type === "view") {
      this.profileService.mode = "view";
      this.router.navigate([
        `supplier/profile/${this.authService.passport.supplier.Mapping.supplierCode}/step1`,
      ]);
      this.profileService.isViewSupplier = true;
    } else if (type === "edit") {
      this.profileService.mode = "edit";
      this.router.navigate([
        `supplier/profile/${this.authService.passport.supplier.Mapping.supplierCode}/step1`,
      ]);
    } else if (type === "history") {
      this.router.navigate([
        `supplier/history/${this.authService.passport.supplier.Mapping.supplierCode}`,
      ]);
    } else if (type === "helpPage") {
      this.router.navigate([`supplier/helpPage`]);
    }
  }

  navNotiTo() {
    this.router.navigate([
      `supplier/onboarding/process/${this.authService.passport.supplier.Mapping.supplierCode}`,
    ]);
  }

  turnToTsm() {
    if (this.isTsmId) {
      const env = sessionStorage.getItem("tsmEnv");
      const tsmUrl =
        env === "dev"
          ? environment.relationshipdev_url
          : env === "perf"
          ? environment.relationshipperf_url
          : env === "hotfix"
          ? environment.relationshiphotfix_url
          : env === "stage"
          ? environment.relationshipstage_url
          : env === "test"
          ? environment.relationshiptest_url
          : environment.relationship_url;
      const countryServed = getTSMCountryServedQuery(
        this.profileService.supplierModel.Mapping.setUpCountry,
      );
      const url =
        tsmUrl +
        this.authService.passport.supplier.Mapping.TsmId +
        "?countryServed=" +
        countryServed +
        "&login_hint=" +
        this.authService.passport.supplier.Mapping.supplierEmail;
      console.debug(`Jump to TSM url: ${url}`);
      window.location.href = url;
    }
  }
  getIsCompleted() {
    if (
      this.profileService.checkTaskIsComplete(
        ProcessNames.generic_supplierSetup,
        SetupProcess.profile_draft
      ) ||
      this.authService.passport.supplier.Mapping.status ===
        SupplierStatus.onboarded ||
      this.authService.passport.supplier.Mapping.status ===
        SupplierStatus.terminated
    ) {
      return true;
    } else {
      return false;
    }
  }

  // show edit button
  // getIsCompletedAndIsSSTRejectOrQCApprove() {

  //   // workflow comments: remove old verify
  //   // if (this.authService.passport.supplier.Mapping.detailStatus === SupplierPendingStatus.SST_review
  //   //   && this.authService.passport.supplier.Mapping.AP_reviewStatus === AP_ReviewStatus.SST_reject) {
  //   //   const statusHistoryLength: number = this.authService.passport.supplier.Mapping.AP_statusHistories.length
  //   //   const lastStatusData: StatusModel = this.authService.passport.supplier.Mapping.AP_statusHistories[statusHistoryLength - 1]
  //   //   const value: any = Object.values(lastStatusData)[0]
  //   //   if (value.rejectTo === 'supplier') {
  //   //     this.supplierHeaderService.isSSTRejectOrQCApprove = true
  //   //   } else {
  //   //     this.supplierHeaderService.isSSTRejectOrQCApprove = false
  //   //   }
  //   // } else if (this.authService.passport.supplier.Mapping.AP_reviewStatus === AP_ReviewStatus.SST_reject
  //   //   || (this.authService.passport.supplier.Mapping.status === SupplierStatus.onboarded
  //   //     && (this.authService.passport.supplier.Mapping.AP_statusHistories.length === 0
  //   //       || this.authService.passport.supplier.Mapping.AP_reviewStatus === AP_ReviewStatus.QC_approve))) {
  //   //   this.supplierHeaderService.isSSTRejectOrQCApprove = true
  //   // } else {
  //   //   this.supplierHeaderService.isSSTRejectOrQCApprove = false
  //   // }

  //   // supplier edit button is enable: sst reject and supplier on boarded
  //   if (this.profileService.getUserTaskByTaskKey(AllTaskKey.SST_rejectToSupplier)) {
  //    this.supplierHeaderService.isSSTRejectOrQCApprove = true
  //  } else if (this.profileService.supplierModel.Mapping.supplierChangeRequestStatus === ProcessStatus.pending) {
  //   this.supplierHeaderService.isSSTRejectOrQCApprove = false
  //  } else if (this.profileService.getUserTaskByKey(ProcessNames.generic_supplierMigrateSetup, MigrateProcess.profile_draft)) {
  //   this.supplierHeaderService.isSSTRejectOrQCApprove = true
  //  } else if (this.profileService.supplierModel.Mapping.supplierMigrateSetupStatus === ProcessStatus.pending) {
  //   this.supplierHeaderService.isSSTRejectOrQCApprove = false
  //  } else if (this.profileService.supplierModel.Mapping.status === SupplierStatus.onboarded) {
  //   this.supplierHeaderService.isSSTRejectOrQCApprove = true
  //  } else {
  //    this.supplierHeaderService.isSSTRejectOrQCApprove = false
  //  }
  // }

  get isShowEditBtn() {
    return this.supplierHeaderService.isSSTRejectOrQCApprove;
  }
}
